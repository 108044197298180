var prevScrollpos = window.pageYOffset;
window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("navbar").style.top = "0";
  } else {
    document.getElementById("navbar").style.top = "-7vh";
  }
  prevScrollpos = currentScrollPos;
}



const contactHeading = document.querySelector("#contact");
const contactOpen = document.querySelector("#contactopen");

const projectsHeading = document.querySelector("#projects");
const projectsOpen = document.querySelector("#projectsopen");

const arrow = document.querySelector("#point1");

contactHeading.addEventListener("mouseover", () => {
  document.querySelector("#contactopen").classList.remove("hidden");
  document.querySelector("#contacthover").classList.add("hidden");


});


contactHeading.addEventListener("mouseout", () => {
  document.querySelector("#contactopen").classList.add("hidden");
  document.querySelector("#contacthover").classList.remove("hidden");


});

projectsHeading.addEventListener("mouseover", () => {
  document.querySelector("#projectsopen").classList.remove("hidden");
  document.querySelector("#projectshover").classList.add("hidden");


});


projectsHeading.addEventListener("mouseout", () => {
  document.querySelector("#projectsopen").classList.add("hidden");
  document.querySelector("#projectshover").classList.remove("hidden");


});


contactHeading.addEventListener("mouseover", () => {
  document.querySelector("#point1").classList.add("rotate-r");

});

